import { useEffect, useState } from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { AspectRatio, Box, BoxProps, Button, LinkBox, useBreakpointValue } from '@chakra-ui/react'
import { fontFamilies } from 'composable/chakra/figma-tokens'
import { promoClickUtagLinkEvent, utagLink } from 'helpers/tealiumHelper'
import { DEFAULT_CSS_DESKTOP, DEFAULT_CSS_MOBILE } from './RichText-Utils/richtext-styles'
import { extractPath } from '../../helpers/utils/extract-url'
import { calculateFontStyles } from '../contentstack/utils'
import { imageOptimization } from '../general/components/image-optimization'

export interface ArticleCardProps {
  priority?: boolean
  root?: Omit<BoxProps, 'children'>
  textAlign?: ArticleCardTextAlign
  image?: {
    src?: string
    alt?: string
    ratio?: number
  }
  eyebrow?: BoxProps
  title?: {
    children?: string
    font?: {
      size?: string
      family?: string
    }
  }
  description?: BoxProps
  href?: string
  cta_style?: string
  analyticsTrackingData?: string
  relativeIndex?: number
  textDecoration?: string | null
  isCertonaItem?: boolean
  pID?: string
  pPriceInfo?: any
  certonaListType?: string
}

export type ArticleCardTextAlign = 'left' | 'center' | 'right'

export const ArticleCard = ({
  priority = false,
  textAlign = 'left',
  root,
  image,
  eyebrow,
  title,
  description,
  href,
  cta_style,
  analyticsTrackingData,
  relativeIndex,
  textDecoration,
  isCertonaItem,
  pID,
  pPriceInfo,
  certonaListType,
}: ArticleCardProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [isClient, setIsClient] = useState(false)
  const { font: titleFont } = title ?? {}
  let titleFontFamily

  if (titleFont?.family === 'antonio' || titleFont?.family === 'libre-franklin') {
    titleFontFamily = titleFont?.family === 'antonio' ? fontFamilies.secondary : fontFamilies.primary
  } else {
    titleFontFamily = titleFont?.family || fontFamilies.primary
  }

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(title.font.size ? parseInt(title.font.size) : 400)

  const analyticsLocationIdentifier = extractPath(href ?? '')
  const dataPromotionName =
    analyticsTrackingData && `${analyticsTrackingData} | ${relativeIndex} | ${analyticsLocationIdentifier}`

  const utagLinkClick = () => {
    const utagData = [
      'link',
      {
        tealium_event: 'product_click',
        product_id: [pID],
        product_name: [title?.children],
        product_price: [pPriceInfo?.upperbound],
        product_position: relativeIndex + 1,
        product_list: [certonaListType],
      },
    ]
    utagLink(utagData)
  }
  useEffect(() => {
    setIsClient(true)
  }, [])
  return href ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      textAlign={textAlign}
      data-promotion-name={dataPromotionName}
      {...root}
    >
      {image?.src && (
        <LinkBox
          as={NextLink}
          href={href}
          onClick={() => (isCertonaItem ? utagLinkClick() : promoClickUtagLinkEvent(dataPromotionName))}
          aria-label={title?.children || 'Article link'}
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
          tabIndex={-1}
        >
          <AspectRatio ratio={image?.ratio ?? 1 / 1} position="relative" width="100%" overflow="hidden" mb={4}>
            <Image
              priority={priority}
              src={imageOptimization(image?.src) ?? ''}
              alt={image?.alt ?? ''}
              // Prevents image from being focused when tabbing
              tabIndex={-1}
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </AspectRatio>
        </LinkBox>
      )}

      <LinkBox
        as={NextLink}
        href={href}
        onClick={() => (isCertonaItem ? utagLinkClick() : promoClickUtagLinkEvent(dataPromotionName))}
        _hover={{ textDecoration: 'none' }}
        _focus={{ outline: 'none' }}
        tabIndex={-1}
      >
        {eyebrow?.children && (
          <Box
            textStyle={['eyebrow-50', null, 'eyebrow-75']}
            textColor={['text-primary', null, 'text-primary']}
            maxWidth={['unset', null, 'unset']}
            paddingY={1.5}
            {...eyebrow}
          />
        )}

        {title?.children && (
          <Box
            fontFamily={titleFontFamily}
            fontSize={_titleFontSize}
            fontWeight={titleFontWeight}
            lineHeight={titleLineHeight}
            _hover={{ color: '#6b6b6b !important' }}
            paddingY={1.5}
            tabIndex={0}
            aria-label={title.children}
            sx={{
              textDecoration: textDecoration ? textDecoration : 'none',
              color: 'rgb(21, 25, 28)',
              '&:hover, &:focus': {
                textDecoration: textDecoration ? textDecoration : 'none',
                color: 'rgb(21, 25, 28)',
              },
            }}
          >
            {cta_style === 'button' ? (
              <Button
                variant={'outline'}
                background={'transparent'}
                aria-label={`${title.children} button`}
                alignItems={'center'}
              >
                {title.children}
              </Button>
            ) : (
              title.children
            )}
          </Box>
        )}

        {description?.children && isClient && (
          <Box
            __css={{
              ...(isMobile ? DEFAULT_CSS_MOBILE : DEFAULT_CSS_DESKTOP),
              'h1, h2, h3, h4, h5, h6': {
                fontFamily: titleFontFamily,
              },
            }}
            textStyle={['callouts-blockquote-75-Default', null, 'body-100']}
            textColor={['text', null, 'text']}
            paddingY={1.5}
            {...description}
          />
        )}
      </LinkBox>
    </Box>
  ) : (
    <Box
      tabIndex={0}
      aria-label={title?.children || 'Article Card'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      textAlign={textAlign}
      {...root}
    >
      {image?.src && (
        <AspectRatio ratio={image?.ratio ?? 1 / 1} position="relative" width="100%" overflow="hidden" mb={4}>
          <Image
            priority={priority}
            src={imageOptimization(image?.src) ?? ''}
            alt={image?.alt ?? ''}
            // Prevents image from being focused when tabbing
            tabIndex={-1}
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
          />
        </AspectRatio>
      )}

      {eyebrow?.children && (
        <Box
          textStyle={['eyebrow-50', null, 'eyebrow-75']}
          textColor={['text-primary', null, 'text-primary']}
          maxWidth={['unset', null, 'unset']}
          paddingY={1.5}
          {...eyebrow}
        />
      )}

      {title?.children && (
        <Box
          fontFamily={titleFontFamily}
          fontSize={_titleFontSize}
          fontWeight={titleFontWeight}
          lineHeight={titleLineHeight}
          paddingY={1.5}
          _hover={{ opacity: '#6b6b6b !important' }}
          tabIndex={0}
          aria-label={title.children}
        >
          {title.children}
        </Box>
      )}

      {description?.children && (
        <Box
          __css={{
            ...(isMobile ? DEFAULT_CSS_MOBILE : DEFAULT_CSS_DESKTOP),
            'h1, h2, h3, h4, h5, h6': {
              fontFamily: titleFontFamily,
            },
          }}
          textStyle={['callouts-blockquote-75-Default', null, 'body-100']}
          textColor={['text', null, 'text']}
          paddingY={1.5}
          {...description}
        />
      )}
    </Box>
  )
}
